.main {
  background-color: rgb(240, 255, 240);
  width: 100%;
  height: 100%;
  position: fixed;
}

div.card, #galleryButt.button {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.ui.container.middle {
  left: 50%;
  display: inline-block;
  width: auto;
}

@media (max-width: 960px) {
  #backButt.button {
    position: fixed;
    top: 2%;
    left: 2%;
  }
}
@media (min-width: 961px) {
  #backButt.button {
    position: fixed;
    top: 2%;
    left: 1%;
  }
}
